var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-header py-3"},[_vm._m(0),_c('div',{staticClass:"card-toolbar"},[_c('button',{ref:"kt_save_changes",staticClass:"btn btn-success mr-2",attrs:{"type":"reset"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Save Changes ")]),_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"reset"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancel ")])])]),_c('form',{staticClass:"form"},[_c('div',{staticClass:"card-body"},[_vm._m(1),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3 col-lg-3 col-form-label"},[_vm._v("Username")]),_c('div',{staticClass:"col-lg-9 col-xl-6"},[_c('div',{staticClass:"spinner spinner-sm spinner-success spinner-right"},[_c('input',{ref:"username",staticClass:"form-control form-control-lg form-control-solid",attrs:{"type":"text"},domProps:{"value":_vm.currentUserAccountInfo.username}})])])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3 col-lg-3 col-form-label"},[_vm._v("Email Address")]),_c('div',{staticClass:"col-lg-9 col-xl-6"},[_c('div',{staticClass:"input-group input-group-lg input-group-solid"},[_vm._m(2),_c('input',{ref:"email",staticClass:"form-control form-control-lg form-control-solid",attrs:{"type":"text","placeholder":"Email"},domProps:{"value":_vm.currentUserAccountInfo.email}})]),_vm._m(3)])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3 col-lg-3 col-form-label"},[_vm._v("Language")]),_c('div',{staticClass:"col-lg-9 col-xl-6"},[_c('select',{ref:"language",staticClass:"form-control form-control-lg form-control-solid"},[_vm._l((_vm.languages),function(item,i){return [_c('option',{key:i,domProps:{"value":item,"selected":_vm.currentUserAccountInfo.language == item
                    ? true
                    : false}},[_vm._v(" "+_vm._s(item)+" ")])]})],2)])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3 col-lg-3 col-form-label"},[_vm._v("Time Zone")]),_c('div',{staticClass:"col-lg-9 col-xl-6"},[_c('select',{ref:"time_zone",staticClass:"form-control form-control-lg form-control-solid"},[_vm._l((_vm.time_zones),function(item,i){return [_c('option',{key:i,domProps:{"value":item,"selected":_vm.currentUserAccountInfo.time_zone == item
                    ? true
                    : false}},[_vm._v(" "+_vm._s(item)+" ")])]})],2)])]),_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-xl-3 col-lg-3 col-form-label"},[_vm._v("Communication")]),_c('div',{staticClass:"col-lg-9 col-xl-6"},[_c('div',{staticClass:"checkbox-inline"},[_c('label',{staticClass:"checkbox"},[_c('input',{ref:"email_com",attrs:{"type":"checkbox"},domProps:{"checked":_vm.currentUserAccountInfo.communication
                    .email}}),_c('span'),_vm._v(" Email")]),_c('label',{staticClass:"checkbox"},[_c('input',{ref:"sms_com",attrs:{"type":"checkbox"},domProps:{"checked":_vm.currentUserAccountInfo.communication.sms}}),_c('span'),_vm._v(" SMS")]),_c('label',{staticClass:"checkbox"},[_c('input',{ref:"phone_com",attrs:{"type":"checkbox"},domProps:{"checked":_vm.currentUserAccountInfo.communication
                    .phone}}),_c('span'),_vm._v(" Phone")])])])]),_c('div',{staticClass:"separator separator-dashed my-5"}),_vm._m(4),_vm._m(5),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3 col-lg-3 col-form-label"},[_vm._v("Password reset verification")]),_c('div',{staticClass:"col-lg-9 col-xl-6"},[_c('div',{staticClass:"checkbox-single"},[_c('label',{staticClass:"checkbox m-0"},[_c('input',{ref:"verification",attrs:{"type":"checkbox"},domProps:{"checked":_vm.currentUserAccountInfo.verification}}),_c('span'),_vm._v(" Require personal information to reset your password.")])]),_vm._m(6),_c('button',{staticClass:"btn btn-light-danger font-weight-bold btn-sm",attrs:{"type":"button"}},[_vm._v(" Deactivate your account ? ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-title align-items-start flex-column"},[_c('h3',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v(" Account Information ")]),_c('span',{staticClass:"text-muted font-weight-bold font-size-sm mt-1"},[_vm._v("Change your account settings")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('label',{staticClass:"col-xl-3"}),_c('div',{staticClass:"col-lg-9 col-xl-6"},[_c('h5',{staticClass:"font-weight-bold mb-6"},[_vm._v(" Account: ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"la la-at"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"form-text text-muted"},[_vm._v("Email will not be publicly displayed. "),_c('a',{staticClass:"kt-link",attrs:{"href":"#"}},[_vm._v("Learn more")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('label',{staticClass:"col-xl-3"}),_c('div',{staticClass:"col-lg-9 col-xl-6"},[_c('h5',{staticClass:"font-weight-bold mb-6"},[_vm._v(" Security: ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3 col-lg-3 col-form-label"},[_vm._v("Login verification")]),_c('div',{staticClass:"col-lg-9 col-xl-6"},[_c('button',{staticClass:"btn btn-light-primary font-weight-bold btn-sm",attrs:{"type":"button"}},[_vm._v(" Setup login verification ")]),_c('p',{staticClass:"form-text text-muted pt-2"},[_vm._v(" After you log in, you will be asked for additional information to confirm your identity and protect your account from being compromised. "),_c('a',{staticClass:"font-weight-bold",attrs:{"href":"#"}},[_vm._v("Learn more")]),_vm._v(". ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"form-text text-muted py-2"},[_vm._v(" For extra security, this requires you to confirm your email or phone number when you reset your password. "),_c('a',{staticClass:"font-weight-boldk",attrs:{"href":"#"}},[_vm._v("Learn more")]),_vm._v(". ")])
}]

export { render, staticRenderFns }